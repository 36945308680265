import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        siteDesc: description
        sitetitle: title
        image
        siteUrl
      }
    }
  }
`

const SEO = ({ title ,description,}) => {
  const{site} =useStaticQuery(query);
  const{siteDesc,sitetitle,image}=site.siteMetadata;
  
  return <Helmet title={`${title} | ${sitetitle}`}>
    <meta name="description" content={description || siteDesc} />
    <meta name="image" content={image}/>
    <meta name="MP2IT" content="MP2IT"/>
    <meta name="MPRW" content="MPRW"/>
    <meta name="MPBS" content="MPBS"/>
    <meta name="MP RESEARCH WORK" content="MP RESEARCH WORK"/>
    <meta name="QUERYEX" content="QUERYEX"/>
    <meta name="WRIRK" content="WRIRK"/>
  </Helmet>
}
export default SEO
